@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  height: auto;
}

.title {
  @include font-style-default;
  margin: 0;
  color: var(--text-30);
}
